module services {
    export module master {
        export class counterService implements interfaces.master.ICounterService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all Terms Of Payment for a dropdown/autocomplete list
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Counter/GetDropdownList", {
                    ownerEntityId: '@ownerEntityId',
                    counterTypeId: '@counterTypeId',
                    isInbound: '@isInbound'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }
        }
    }
    angular.module("app").service("counterService", services.master.counterService);
}